<script>
export default {
    props: {
        number: {
            type: String,
            required: true,
        },
        amount: {
            type: Number,
            required: true,
        },
        shift: {
            type: Number,
            required: true,
        },
        activeNumber: {
            type: Number,
            required: true,
        },
        focusNumber: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        getShift(n) {
            return this.shift !== 0 ? n + this.shift : n - 1
        },
    },
}
</script>

<template>
    <div :class="$style.numbers">
        <div
            v-for="n in amount"
            :key="n"
            class="number"
            :class="activeNumber === getShift(n) && focusNumber ? $style.hasFocus : ''"
            v-text="number[getShift(n)] ? number[getShift(n)] : ''"
        />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.numbers {
    @include formbox;

    display: flex;
    width: 100%;
    height: 47px;
    overflow: hidden;
    &:not(:last-child) {
        margin-right: 5px;
    }
    div {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: 100%;
        &:not(:last-child) {
            border-right: 1px solid #f5f6fa;
        }
    }
}
.hasFocus {
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px $color-link;
}
</style>
